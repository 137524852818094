import React, { useEffect, useState } from 'react';

function BackgroundImage({ id, pageHeader }) {
  const [imageData, setImageData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(`/riosdevida-web/api/image/${id}`);
        const result = await response.json();

        if (result.code === 100) {
          setImageData(result.image);
          setError(null);
        } else {
          setError(result.message);
          setImageData(null);
        }
      } catch (error) {
        setError('Error to load the image.');
        setImageData(null);
      }
    };

    fetchImage();
  }, [id]);

  if (error) {
    return <p>{error}</p>;
  }

  if (imageData) {
    return (
	  <div
          className="page-header-image"
          style={{
            backgroundImage: `url(data:${imageData.type};base64,${imageData.base64})`
          }}
          ref={pageHeader}
        ></div>
    );
  }

  return <p>Loading...</p>;
}

export default BackgroundImage;
