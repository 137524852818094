import React from "react";
// Importe algum componente de loading, como um spinner
import { Spinner } from "reactstrap";

// Seus outros imports continuam...
import { useGlobalState } from "../GlobalState"; // Certifique-se de que o caminho está correto
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import TextContent from "./index-sections/TextContent.js";
import Tabs from "./index-sections/Tabs.js";
import Carousel from "./index-sections/Carousel.js";
import DarkFooter from "components/Footers/DarkFooter.js";


function Index() {
  const { fetchedData } = useGlobalState();

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  // Exibir um spinner de carregamento enquanto `fetchedData` for null
  if (!fetchedData) {
    return (
      <div className="loading-screen">
        <Spinner color="primary" /> {/* Indicador de carregamento */}
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <IndexNavbar {...fetchedData.info} />
      <div className="wrapper">
        <IndexHeader {...fetchedData} />
        {fetchedData.region ? (
          <div className="main">
            {fetchedData.region.contents.map((content, index) =>
              content.type === "TEXT" ? (
                <TextContent key={index} {...content} />
              ) : content.type === "TABS" ? (
                <Tabs key={index} {...content} />
              ) : (
                <Carousel key={index} {...content} />
              )
            )}
          </div>
        ) : (
          <div>NO CONTENT FOUND!</div>
        )}
        <DarkFooter />
      </div>
    </>
  );
}

export default Index;
