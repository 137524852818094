import React, { createContext, useState, useContext } from "react";

// Create Contexts for Global State and Update Functions
const GlobalStateContext = createContext();
const GlobalStateUpdateContext = createContext();

// Custom hooks to use the contexts
export const useGlobalState = () => useContext(GlobalStateContext);
export const useGlobalStateUpdate = () => useContext(GlobalStateUpdateContext);

export const GlobalStateProvider = ({ children }) => {
  const [language, setLanguage] = useState("def");
  const [location, setLocation] = useState("def");
  const [fetchedData, setFetchedData] = useState(null);
  const [loading, setLoading] = useState(true); // Estado de carregamento

  const fetchDataFromServer = (language, location) => {
    setLoading(true); // Ativar carregamento antes de buscar dados
    fetch(`/riosdevida-web/api/content/${location}/${language}`)
      .then((response) => response.json())
      .then((data) => {
        setFetchedData(data);
        setLoading(false); // Desativar carregamento ao concluir
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Desativar carregamento mesmo com erro
      });
  };

  const updateLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    fetchDataFromServer(newLanguage, location);
  };

  const updateLocation = (newLocation) => {
    setLocation(newLocation);
    fetchDataFromServer(language, newLocation);
  };

  React.useEffect(() => {
    fetchDataFromServer("def", "def");
  }, []);

  return (
    <GlobalStateContext.Provider value={{ language, location, fetchedData, loading }}>
      <GlobalStateUpdateContext.Provider value={{ updateLanguage, updateLocation }}>
        {children}
      </GlobalStateUpdateContext.Provider>
    </GlobalStateContext.Provider>
  );
};
