/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import BackgroundImage from './BackgroundImage';
import Image from "../../views/index-sections/Image.js"; 
import { useGlobalState } from "../../GlobalState";

// core components

function IndexHeader({name, region}) {
  let pageHeader = React.createRef();
  const { websiteName } = useGlobalState();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
	(region ?
	    <>
	      <div className="page-header clear-filter" filter-color="blue">
	        <BackgroundImage id={region.image.id} pageHeader={pageHeader} />
	        <Container>
	          <div className="content-center brand">
	          	<Image id={region.logo.id} className="n-logo" />
	            <h1 className="h1-seo">{name}</h1>
	            <h3>{region.name}</h3>
	          </div>          
	        </Container>
	      </div>
	    </>
    : <div>NO CONTENT FOUND!</div>
    )
  );
}

export default IndexHeader;
